.card {
  display: flex;
  height: 20rem;
  width: 15rem;
  padding: 1rem;
  margin: 1rem;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0 0 10px 1px #000;
}

.card_image {
  display: flex;
  width: 13rem;
  height: 13rem;
}

.card_image img {
  border-radius: 5%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card p {
  color: lightslategray;
}
