* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  color: var(--gradient-text-header);
  width: 100vw;
  height: 100vh;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

/* h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
}

h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
} */

.section__padding {
  padding: 4rem 8rem;
}

.loader_page {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.loader_page-logo {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  box-shadow: 0 0 60px 1px #fff;
}

.page {
  display: flex;
  padding: 2rem 6rem;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.roll-out-right {
  -webkit-animation: roll-out-right 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045)
    both;
  animation: roll-out-right 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
}

@-webkit-keyframes roll-out-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(540deg);
    transform: translateX(1000px) rotate(540deg);
    opacity: 0;
  }
}
@keyframes roll-out-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(540deg);
    transform: translateX(1000px) rotate(540deg);
    opacity: 0;
  }
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 1.2s ease-in both;
  animation: fade-in-fwd 1.2s ease-in both;
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-200px) perspective(200px);
    transform: translateZ(-200px) perspective(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) perspective(200px);
    transform: translateZ(0) perspective(200px);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-200px) perspective(200px);
    transform: translateZ(-200px) perspective(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) perspective(200px);
    transform: translateZ(0) perspective(200px);
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .section__padding {
    padding: 3rem 6rem;
  }
}

@media screen and (max-width: 800px) {
  .section__padding {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 590px) {
  .section__padding {
    padding: 1rem 2rem;
  }
}
