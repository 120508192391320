.placeholder_page {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
}

.placeholder_page h1 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}
