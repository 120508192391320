.card {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: saturate(120%) blur(15px);
}

.card_image {
  display: flex;
  width: 13rem;
  height: 13rem;
}

.card_image img {
  border-radius: 5%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card p {
  color: lightslategray;
}
