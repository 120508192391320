@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  font-family: "Poppins", sans-serif;
  --color-background: linear-gradient(
    297deg,
    rgba(35, 32, 166, 1) 0%,
    rgba(57, 78, 205, 1) 59%
  );

  --gradient-text-header: linear-gradient(90deg, #b36e1f 0%, #411478 100%);

  --gradient-text-sub: linear-gradient(90deg, #411478 0%, #b36e1f 100%);

  --color-bg: #040c18;
}
