.navbar {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
  padding: 2rem;
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-links_logo {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 20px 1px #fff; /* inner white */
  cursor: pointer;
  margin-right: 2rem;
  z-index: 2;
}

.navbar-links_logo img {
  width: 100%;
  height: 100%;
}

.navbar-links_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.navbar-contact {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .navbar-links_container p, */
.navbar-links_container a,
/* .navbar-contact p, */
.navbar-menu_container p,
.navbar-menu_container a {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.navbar-contact button,
.navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: white;
  background: #606060;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: 5px 0 30px 2px #411478, -5px 0 30px 2px #673905;
}

.navbar-menu {
  color: white;
  display: none;
  margin-left: 2rem;
  z-index: 1;
}

.link {
  position: relative;
  color: white;
  width: fit-content;
}

.link::after {
  content: "";
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  right: 0;
  border-radius: 10px;
  background-color: #b36e1f;
  height: 15%;
  transform: scaleX(0);
  transform-origin: left;
  transition: ease-in 200ms;
}

.link:hover::after {
  transform: scaleX(1);
  transition: ease-in 200ms;
  transform-origin: left;
}

@media screen and (max-width: 1000px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }

  .link::after {
    content: none;
  }
}

@media screen and (max-width: 590px) {
  .navbar-contact {
    display: none;
  }
}
