.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: auto;
  margin-top: 2rem;
  padding: 2rem 12rem;
  color: white;
  background: black;
  justify-content: center;
  /* z-index: -1; */
}

.footer_logo-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  margin-bottom: 1rem;
  /* background-color: red; */
}

.footer_logo-container img {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}

.footer hr {
  width: 80%;
  margin-bottom: 1rem;
}

.footer_columns {
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  justify-content: center;
}

.footer_columns p,
.footer_columns a {
  display: flex;
  color: rgba(255, 255, 255, 0.5);
}

.footer_left,
.footer_middle,
.footer_right {
  width: 100%;
  height: 100%;
}

.footer_social-icon_container {
  display: flex;
  margin-left: -1rem;
}

.footer_social-icon {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
  margin-left: 1rem;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

/* .footer_socials {
  display: flex;
  justify-content: space-between;
} */

@media screen and (max-width: 1000px) {
  .footer {
    padding: 2rem 20%;
  }

  .footer_columns {
    flex-direction: column;
  }
  .footer_left {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .footer_middle {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .footer_right {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .footer_icon {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 590px) {
  .footer_icon {
    height: 20px;
    width: 20px;
  }
}
