.navbar-menu_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: calc(4rem + 50px);
  top: 0;
  right: 0;
  left: 0;
  bottom: -200vh;
  position: absolute;
  z-index: -1;
  animation: color-blend 650ms both;
}

.navbar-menu_links {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.navbar-menu_links a {
  display: flex;
  color: white;
  width: fit-content;
  margin: 1rem;
  font-size: 30px;
  cursor: pointer;
  animation: translate-enter 500ms both;
}

.navbar-menu_links a:nth-child(7) {
  animation-delay: 150ms;
}
.navbar-menu_links a:nth-child(6) {
  animation-delay: 125ms;
}
.navbar-menu_links a:nth-child(5) {
  animation-delay: 100ms;
}
.navbar-menu_links a:nth-child(4) {
  animation-delay: 75ms;
}
.navbar-menu_links a:nth-child(3) {
  animation-delay: 50ms;
}
.navbar-menu_links a:nth-child(2) {
  animation-delay: 25ms;
}

@keyframes translate-enter {
  0% {
    transform: translateX(-50vw);
    /* transform-origin: left; */
  }
  100% {
    transform: translateX(0);
    /* transform-origin: left; */
  }
}

@keyframes color-blend {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
