@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
:root {
  font-family: "Poppins", sans-serif;
  --color-background: linear-gradient(
    297deg,
    rgba(35, 32, 166, 1) 0%,
    rgba(57, 78, 205, 1) 59%
  );

  --gradient-text-header: linear-gradient(90deg, #b36e1f 0%, #411478 100%);

  --gradient-text-sub: linear-gradient(90deg, #411478 0%, #b36e1f 100%);

  --color-bg: #040c18;
}

.footer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: auto;
  margin-top: 2rem;
  padding: 2rem 12rem;
  color: white;
  background: black;
  justify-content: center;
  /* z-index: -1; */
}

.footer_logo-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 1rem;
  /* background-color: red; */
}

.footer_logo-container img {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}

.footer hr {
  width: 80%;
  margin-bottom: 1rem;
}

.footer_columns {
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  justify-content: center;
}

.footer_columns p,
.footer_columns a {
  display: flex;
  color: rgba(255, 255, 255, 0.5);
}

.footer_left,
.footer_middle,
.footer_right {
  width: 100%;
  height: 100%;
}

.footer_social-icon_container {
  display: flex;
  margin-left: -1rem;
}

.footer_social-icon {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
  margin-left: 1rem;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

/* .footer_socials {
  display: flex;
  justify-content: space-between;
} */

@media screen and (max-width: 1000px) {
  .footer {
    padding: 2rem 20%;
  }

  .footer_columns {
    flex-direction: column;
  }
  .footer_left {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .footer_middle {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .footer_right {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .footer_icon {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 590px) {
  .footer_icon {
    height: 20px;
    width: 20px;
  }
}

.card {
  display: flex;
  height: 20rem;
  width: 15rem;
  padding: 1rem;
  margin: 1rem;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0 0 10px 1px #000;
}

.card_image {
  display: flex;
  width: 13rem;
  height: 13rem;
}

.card_image img {
  border-radius: 5%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card p {
  color: lightslategray;
}

.navbar {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
  padding: 2rem;
}

.navbar-links {
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-links_logo {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 20px 1px #fff; /* inner white */
  cursor: pointer;
  margin-right: 2rem;
  z-index: 2;
}

.navbar-links_logo img {
  width: 100%;
  height: 100%;
}

.navbar-links_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.navbar-contact {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .navbar-links_container p, */
.navbar-links_container a,
/* .navbar-contact p, */
.navbar-menu_container p,
.navbar-menu_container a {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.navbar-contact button,
.navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: white;
  background: #606060;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: 5px 0 30px 2px #411478, -5px 0 30px 2px #673905;
}

.navbar-menu {
  color: white;
  display: none;
  margin-left: 2rem;
  z-index: 1;
}

.link {
  position: relative;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.link::after {
  content: "";
  position: absolute;
  bottom: -0.25rem;
  left: 0;
  right: 0;
  border-radius: 10px;
  background-color: #b36e1f;
  height: 15%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: ease-in 200ms;
}

.link:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  transition: ease-in 200ms;
  -webkit-transform-origin: left;
          transform-origin: left;
}

@media screen and (max-width: 1000px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }

  .link::after {
    content: none;
  }
}

@media screen and (max-width: 590px) {
  .navbar-contact {
    display: none;
  }
}

.placeholder_page {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
}

.placeholder_page h1 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: saturate(120%) blur(15px);
          backdrop-filter: saturate(120%) blur(15px);
}

.card_image {
  display: flex;
  width: 13rem;
  height: 13rem;
}

.card_image img {
  border-radius: 5%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card p {
  color: lightslategray;
}

.navbar-menu_container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: calc(4rem + 50px);
  top: 0;
  right: 0;
  left: 0;
  bottom: -200vh;
  position: absolute;
  z-index: -1;
  -webkit-animation: color-blend 650ms both;
          animation: color-blend 650ms both;
}

.navbar-menu_links {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.navbar-menu_links a {
  display: flex;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem;
  font-size: 30px;
  cursor: pointer;
  -webkit-animation: translate-enter 500ms both;
          animation: translate-enter 500ms both;
}

.navbar-menu_links a:nth-child(7) {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
}
.navbar-menu_links a:nth-child(6) {
  -webkit-animation-delay: 125ms;
          animation-delay: 125ms;
}
.navbar-menu_links a:nth-child(5) {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}
.navbar-menu_links a:nth-child(4) {
  -webkit-animation-delay: 75ms;
          animation-delay: 75ms;
}
.navbar-menu_links a:nth-child(3) {
  -webkit-animation-delay: 50ms;
          animation-delay: 50ms;
}
.navbar-menu_links a:nth-child(2) {
  -webkit-animation-delay: 25ms;
          animation-delay: 25ms;
}

@-webkit-keyframes translate-enter {
  0% {
    -webkit-transform: translateX(-50vw);
            transform: translateX(-50vw);
    /* transform-origin: left; */
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    /* transform-origin: left; */
  }
}

@keyframes translate-enter {
  0% {
    -webkit-transform: translateX(-50vw);
            transform: translateX(-50vw);
    /* transform-origin: left; */
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    /* transform-origin: left; */
  }
}

@-webkit-keyframes color-blend {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

@keyframes color-blend {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.9);
  }
}

:root {
  --text-wheel-font-size: 1rem;
}

.gradient_text {
  background: var(--gradient-text-header);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}

.text__carousel {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 1rem;
  font-size: var(--text-wheel-font-size);
  /* z-index: -1; */
}

.text__intro {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: lightslategray;
  align-items: center;
}

.text__mask {
  display: flex;
  flex: 1 1;
  padding-left: 0.5rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
}

.text__mask ul {
  position: relative;
  width: 100%;
}

.text__mask li {
  list-style: none;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
}

.text__mask li:nth-child(1) {
  -webkit-animation: cycle1 20s ease-in infinite;
          animation: cycle1 20s ease-in infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.text__mask li:nth-child(2) {
  -webkit-animation: cycle2 20s ease-in infinite;
          animation: cycle2 20s ease-in infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.text__mask li:nth-child(3) {
  -webkit-animation: cycle3 20s ease-in infinite;
          animation: cycle3 20s ease-in infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.text__mask li:nth-child(4) {
  -webkit-animation: cycle4 20s ease-in infinite;
          animation: cycle4 20s ease-in infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@keyframes cycle1 {
  0% {
    top: calc(-2.5rem - 1rem * 1.5);
    top: calc(-2.5rem - var(--text-wheel-font-size) * 1.5);
    opacity: 0;
  }
  2.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  22.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  25%,
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@-webkit-keyframes cycle1 {
  0% {
    top: calc(-2.5rem - 1rem);
    top: calc(-2.5rem - var(--text-wheel-font-size));
    opacity: 0;
  }
  2.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  22.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  25%,
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@keyframes cycle2 {
  0%,
  25% {
    top: calc(-2.5rem - 1rem * 1.5);
    top: calc(-2.5rem - var(--text-wheel-font-size) * 1.5);
    opacity: 0;
  }
  27.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  47.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  50%,
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@-webkit-keyframes cycle2 {
  0%,
  25% {
    top: calc(-2.5rem - 1rem);
    top: calc(-2.5rem - var(--text-wheel-font-size));
    opacity: 0;
  }
  27.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  47.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  50%,
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@keyframes cycle3 {
  0%,
  50% {
    top: calc(-2.5rem - 1rem * 1.5);
    top: calc(-2.5rem - var(--text-wheel-font-size) * 1.5);
    opacity: 0;
  }
  52.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  72.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  75%,
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@-webkit-keyframes cycle3 {
  0%,
  50% {
    top: calc(-2.5rem - 1rem);
    top: calc(-2.5rem - var(--text-wheel-font-size));
    opacity: 0;
  }
  52.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  72.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  75%,
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@keyframes cycle4 {
  0%,
  75% {
    top: calc(-2.5rem - 1rem * 1.5);
    top: calc(-2.5rem - var(--text-wheel-font-size) * 1.5);
    opacity: 0;
  }
  77.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  97.5% {
    top: calc(0rem - 1rem * 1.5);
    top: calc(0rem - var(--text-wheel-font-size) * 1.5);
    opacity: 1;
  }
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@-webkit-keyframes cycle4 {
  0%,
  75% {
    top: calc(-2.5rem - 1rem);
    top: calc(-2.5rem - var(--text-wheel-font-size));
    opacity: 0;
  }
  77.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  97.5% {
    top: calc(0rem - 1rem);
    top: calc(0rem - var(--text-wheel-font-size));
    opacity: 1;
  }
  100% {
    top: 2.5rem;
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    --text-wheel-font-size: 0.75rem;
  }
}

@media screen and (max-width: 800px) {
  :root {
    --text-wheel-font-size: 0.5rem;
  }
}

@media screen and (max-width: 590px) {
}

@media screen and (max-width: 440px) {
}


/* .section__padding {
  padding: 4rem 12rem;
} */

.resume__page {
  display: flex;
  color: white;
  flex-direction: row-reverse;
}

.resume__page h1 {
  font-size: 2.5rem;
  color: lightslategray;
}

.resume__page h2 {
  font-size: 1.75rem;
}
.resume__page p {
  font-size: 1rem;
}

.resume__main-content {
  display: flex;
  /* color: white; */
  /* font-size: 1.25rem; */
  /* background-color: blue; */
  width: 100%;
  flex-direction: column;
}

.resume__margin-content {
  display: flex;
  margin-right: 2rem;
  /* font-size: 1.25rem; */
  /* background-color: red; */
  flex-direction: column;
  width: 30vw;
}

.resume__margin-content li {
  list-style: none;
}

@media screen and (max-width: 1000px) {
  .resume__page h1 {
    font-size: 2rem;
  }

  .resume__page h2 {
    font-size: 1.5rem;
  }
  .resume__page p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .resume__page h1 {
    font-size: 1.5rem;
  }

  .resume__page h2 {
    font-size: 1.25rem;
  }
  .resume__page p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .resume__page {
    flex-direction: column;
    text-align: center;
  }

  .resume__margin-content {
    flex-direction: row;
    width: 100%;
  }

  .resume__margin-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem;
  }
}

.home__page {
  display: flex;
  flex-direction: column;
  /* z-index: -2; */
}

.home__page h1 {
  font-size: 3.5rem;
}

.home__page h2 {
  font-size: 3rem;
}

.home__page h3 {
  font-size: 2.5rem;
}

.home__page h4 {
  font-size: 2rem;
}

.home__page p {
  font-size: 1.5rem;
}

.body__main-header {
  display: flex;
  /* font-size: 40px; */
  text-align: left;
}

.body__main-content {
  display: flex;
  flex-direction: row;
}

.body__headshot-container {
  display: flex;
  position: absolute;
  /* position: sticky; */
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  top: 25vh;
  left: 55vw;
  width: 25rem;
  height: 30rem;
  /* background-color: red; */
}

.body__headshot-container img {
  position: relative;
  width: 80%;
  height: 80%;
  /* top: 0; */
  border-radius: 5%;
  object-fit: cover;
}

.body__headshot-backing_orange {
  position: absolute;
  background-color: rgba(179, 110, 31, 1);
  width: 70%;
  height: 70%;
  border-radius: 5%;
  -webkit-transform: translate(35px, 35px);
          transform: translate(35px, 35px);
}

.body__headshot-backing_gray {
  position: absolute;
  background-color: lightslategray;
  width: 70%;
  height: 70%;
  border-radius: 5%;
  -webkit-transform: translate(-35px, -35px);
          transform: translate(-35px, -35px);
}

.body__intro-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 3rem;
  z-index: -2;
}

.body__information-text {
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.body__intro-container h2 {
  color: lightslategray;
}

.body__intro-container h4 {
  color: white;
}

.body__intro-container p {
  color: white;
  /* font-size: 20px; */
}

.gradient__bg {
  /* ff 3.6+ */

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, #002853 0%, #040c18 100%);
}

.gradient__text {
  background: linear-gradient(
    90deg,
    rgba(179, 110, 31, 1) 0%,
    rgba(179, 110, 31, 1) 101%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}

@media screen and (max-width: 1200px) {
  .body__headshot-container {
    position: relative;
    top: auto;
    left: 35vw;
  }
}

@media screen and (max-width: 1100px) {
  .body__headshot-container {
    left: 25vw;
  }
}

@media screen and (max-width: 1000px) {
  .body__main-header,
  .body__main-header p,
  .body__main-header h1 {
    font-size: 50px;
    text-align: center;
    justify-content: center;
  }

  .body__headshot-container {
    left: auto;
  }

  .body__headshot-row_orientation-container {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
    align-items: center;
  }

  .body__intro-container {
    margin-top: 0;
    text-align: center;
  }

  .body__information-text {
    margin-left: 0;
  }
}

@media screen and (max-width: 800px) {
  .body__main-header,
  .body__main-header h1 {
    font-size: 40px;
  }

  .body__intro-container,
  .body__intro-container h2 {
    font-size: 30px;
  }

  .body__intro-container,
  .body__intro-container p {
    font-size: 20px;
  }

  .card_container {
    flex-direction: column;
  }

  .body__headshot-container {
    -webkit-transform: scale(0.8, 0.8);
            transform: scale(0.8, 0.8);
  }
}

@media screen and (max-width: 700px) {
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
  color: var(--gradient-text-header);
  width: 100vw;
  height: 100vh;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

/* h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
}

h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
} */

.section__padding {
  padding: 4rem 8rem;
}

.loader_page {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.loader_page-logo {
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  box-shadow: 0 0 60px 1px #fff;
}

.page {
  display: flex;
  padding: 2rem 6rem;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.roll-out-right {
  -webkit-animation: roll-out-right 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045)
    both;
  animation: roll-out-right 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
}

@-webkit-keyframes roll-out-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(540deg);
    transform: translateX(1000px) rotate(540deg);
    opacity: 0;
  }
}
@keyframes roll-out-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(540deg);
    transform: translateX(1000px) rotate(540deg);
    opacity: 0;
  }
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 1.2s ease-in both;
  animation: fade-in-fwd 1.2s ease-in both;
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-200px) perspective(200px);
    transform: translateZ(-200px) perspective(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) perspective(200px);
    transform: translateZ(0) perspective(200px);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-200px) perspective(200px);
    transform: translateZ(-200px) perspective(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) perspective(200px);
    transform: translateZ(0) perspective(200px);
    opacity: 1;
  }
}

@media screen and (max-width: 1000px) {
  .section__padding {
    padding: 3rem 6rem;
  }
}

@media screen and (max-width: 800px) {
  .section__padding {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 590px) {
  .section__padding {
    padding: 1rem 2rem;
  }
}

